export default [
  {
    title: '首頁',
    icon: 'home',
    route: 'client-home',
  },
  // ----------------------------------------------------------------------
  //    SHOP:主機服務/加值服務
  // ----------------------------------------------------------------------
  {
    header: '主機服務',
    icon: 'server',
    children: [
      {
        title: '實體主機',
        icon: 'hdd',
      },
      {
        title: '雲端VPS主機',
        icon: 'project-diagram',
      },
      {
        title: '虛擬主機',
        icon: 'laptop-medical',
      },
    ],
  },
  {
    header: '加值服務',
    icon: 'plus-circle',
    children: [
      {
        title: '網域註冊',
        icon: 'edit',
      },
      {
        title: 'SSL',
        icon: 'lock',
      },
    ],
  },
  // ----------------------------------------------------------------------
  //    MEMBER:會員中心
  // ----------------------------------------------------------------------
  {
    title: '會員中心',
    icon: 'user',
    route: 'member',
  },
  // ----------------------------------------------------------------------
  //    SERVICE:服務
  // ----------------------------------------------------------------------
  {
    header: '服務',
    icon: 'concierge-bell',
    children: [
      {
        title: '我的服務',
        icon: 'id-card',
      },
      {
        title: '帳務',
        icon: 'dollar-sign',
      },
      {
        title: '技術支援',
        icon: 'life-ring',
        children: [
          {
            title: '線上客服',
            icon: 'comment-dots',
          },
          {
            title: '建立服務單',
            icon: 'file-signature',
          },
          {
            title: '常見問題',
            icon: 'question-circle',
          },
        ],
      },
      {
        title: '文件下載',
        icon: 'download',
      },
    ],
  },
  // ----------------------------------------------------------------------
  //    ABOUT:品牌故事
  // ----------------------------------------------------------------------
  {
    header: '品牌故事',
    icon: 'anchor',
    children: [
      {
        title: '團隊介紹',
        icon: 'user-friends',
        route: 'team',
      },
      {
        title: '團隊記事',
        icon: 'pencil-alt',
      },
    ],
  },
  // ----------------------------------------------------------------------
  //    CONTACT:聯絡我們
  // ----------------------------------------------------------------------
  {
    title: '聯絡我們',
    icon: 'envelope',
  },
]
