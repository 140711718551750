import { getUserRoutes } from '@/auth/utils'

export const can = ability => {
  if (ability === undefined) return true
  const userRoutes = getUserRoutes()
  const result = userRoutes.map(item => item.codename).indexOf(ability)
  if (result >= 0) return true
  return false
}

export const canViewVerticalNavMenuLink = item => can(item.ability)

export const canViewVerticalNavMenuGroup = item => {
  const hasAnyVisibleChild = item.children.some(i => can(i.ability))

  if (!(item.resource)) {
    return hasAnyVisibleChild
  }
  return can(item.action, item.ability) && hasAnyVisibleChild
}

export const canViewVerticalNavMenuHeader = item => can(item.ability)

export const canViewHorizontalNavMenuLink = item => can(item.ability)

export const canViewHorizontalNavMenuHeaderLink = item => can(item.ability)

export const canViewHorizontalNavMenuGroup = item => {
  const hasAnyVisibleChild = item.children.some(i => can(i.ability))

  if (!(item.action && item.ability)) {
    return hasAnyVisibleChild
  }
  return can(item.ability) && hasAnyVisibleChild
}

export const canViewHorizontalNavMenuHeaderGroup = item => {
  // eslint-disable-next-line arrow-body-style
  const hasAnyVisibleChild = item.children.some(grpOrItem => {
    return grpOrItem.children ? canViewHorizontalNavMenuGroup(grpOrItem) : canViewHorizontalNavMenuLink(grpOrItem)
  })

  if (!(item.ability)) {
    return hasAnyVisibleChild
  }
  return can(item.ability) && hasAnyVisibleChild
}
