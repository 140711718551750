const admin = [
  {
    title: '公告',
    icon: 'home',
    route: 'admin-home',
  },
  {
    title: '個人檔案',
    icon: 'user',
    route: 'admin-profile',
  },
  {
    title: '員工列表',
    icon: 'id-card',
    route: 'admin-personnel-list',
  },
]
export default admin
