<template>
  <b-nav-item-dropdown
    class="dropdown-notification m-0"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <div>
        <feather-icon
          :badge="unread"
          badge-classes="bg-danger"
          icon="BellIcon"
          size="21"
          :class="`${unread !== 0 ? 'animated infinite swing delay-2s' : null}`"
          :style="`color: ${isDark ? '#d0d2d6': '#5e5873' }`"
        />
      </div>
      <!-- class="animated infinite wobble delay-1s" -->
    </template>

    <!-- Header -->
    <div v-if="isLogging">
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">
            通知
          </h4>
          <b-badge
            v-if="unread !== 0"
            pill
            variant="light-primary"
          >
            {{ unread }} 則通知
          </b-badge>
        </div>
      </li>

      <!-- Notifications -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <!-- System Notification Toggler -->
        <div
          v-if="notifications.length === 0 && unread === 0"
          class="media d-flex align-items-center text-center"
        >
          <h6 class="font-weight-bolder mr-auto mb-0">
            暫無通知
          </h6>
        </div>

        <div v-if="notifications">
          <b-link
            v-for="notification in notifications"
            :key="notification.subtitle"
            :to="{ path: `${notification.link}`}"
          >
            <b-media>
              <template #aside>
                <b-avatar
                  size="32"
                  :variant="ui.state[1][notification.state]"
                >
                  <feather-icon :icon="ui.state[0][notification.state]" />
                </b-avatar>
              </template>
              <p class="media-heading">
                <span class="font-weight-bolder">
                  {{ notification.title }}
                </span>

                <small class="text-muted">{{ moment(notification.time).format('YYYY-MM-DD HH:mm:ss') }}</small>
              </p>
              <small class="notification-text">{{ notification.body }}</small>
            </b-media>
          </b-link>
        </div>
      </vue-perfect-scrollbar>

      <!-- Cart Footer -->
      <li class="dropdown-menu-footer"><b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
      >所有通知</b-button>
      </li>
    </div>

  </b-nav-item-dropdown>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { ref, computed } from '@vue/composition-api'
import moment from 'moment'

import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  methods: {
    moment,
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    const unread = ref(0)
    const notifications = ref([])
    const isLogging = ref(false)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const ui = {
      state: [{
        0: 'CoffeeIcon', 1: 'GlobeIcon', 2: 'ShoppingBagIcon', 3: 'HelpCircleIcon', 4: 'AlertTriangleIcon',
      }, {
        0: 'light-success', 1: 'light-primary', 2: 'light-info', 3: 'light-warning', 4: 'light-danger',
      }],
    }

    return {
      isDark,
      isLogging,
      ui,

      unread,
      notifications,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>

</style>
