<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div>
        <div
          v-if="$store.state['app'].userData"
          class="d-sm-flex d-none user-nav"
          :style="`color: ${isDark ? '#d0d2d6': '#5e5873' }`"
        >
          <p class="user-name font-weight-bolder mb-0">
            {{ $store.state['app'].userData.name }}
          </p>
          <span class="user-status">{{ $store.state['app'].userData.role.name }}</span>
        </div>
        <b-avatar
          size="40"
          :src=" $store.state['app'].userData ? $store.state['app'].userData.image : null"
          variant="light-primary"
          class="badge-minimal"
          badge-variant="success"
        >
          <feather-icon
            v-if="!$store.state['app'].userData"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
      </div>
    </template>

    <!-- 欄: 會員登入  -->
    <b-dropdown-item
      v-if="!$store.state['app'].userData"
      :to="{ name: 'auth-login' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>會員登入</span>
    </b-dropdown-item>

    <!-- 欄: 會員註冊  -->
    <b-dropdown-item
      v-if="!$store.state['app'].userData"
      :to="{ name: 'auth-register' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>會員註冊</span>
    </b-dropdown-item>

    <!-- 欄: 忘記密碼  -->
    <b-dropdown-item
      v-if="!$store.state['app'].userData"
      :to="{ name: 'auth-forgot-password' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CheckSquareIcon"
        class="mr-50"
      />
      <span>忘記密碼</span>
    </b-dropdown-item>

    <!-- 欄: 後臺管理  -->
    <b-dropdown-item
      v-if="$store.state['app'].userData.role"
      link-class="d-flex align-items-center"
      @click="updateAdminManu"
    >
      <!-- .isAdmin === 1； -->
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>管理後臺</span>
    </b-dropdown-item>

    <!-- 欄: 目前餘額  -->
    <b-dropdown-item
      v-if="$store.state['app'].userData"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>餘額:$ {{ $store.state['app'].userData.cash.toLocaleString() }}</span>
    </b-dropdown-item>

    <b-dropdown-divider v-if="$store.state['app'].userData" />

    <!-- 欄: 我的訊息  -->
    <b-dropdown-item
      v-if="$store.state['app'].userData"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>我的訊息</span>
    </b-dropdown-item>

    <!-- 欄: 設定  -->
    <b-dropdown-item
      v-if="$store.state['app'].userData"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>設定</span>
    </b-dropdown-item>

    <b-dropdown-divider v-if="$store.state['app'].userData" />

    <!-- 欄: 登出  -->
    <b-dropdown-item
      v-if="$store.state['app'].userData"
      link-class="d-flex align-items-center"
      @click="onSubmitLogout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>登出</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
// API
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import axios from '@axios'
import { logout } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'

// UI
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    updateAdminManu() {
      this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_DISPLAY', 'Admin')
      if (window.location.pathname === '/admin/home') return
      this.$router.push({ name: 'admin-home' })
    },
    onSubmitLogout() {
      axios('/api/user/logout', {
        method: 'POST',
        headers: {
          token: useJwt.getToken(),
        },
      })
      logout()
      if (window.location.pathname === '/home') return
      this.$router.push({ name: 'client-home' })
    },
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return {
      isDark,
    }
  },
}
</script>
