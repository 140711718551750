/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
    ],
  },
}
/* eslint-enable */
