<template>
  <b-nav-item-dropdown
    class="dropdown-cart mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        icon="ShoppingCartIcon"
        size="21"
        :style="`color: ${isDark ? '#d0d2d6': '#5e5873' }`"
      />
    </template>

  </b-nav-item-dropdown>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

import {
  BNavItemDropdown,
} from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
