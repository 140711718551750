<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <span class="brand-logo">
            <b-img
              v-if="!isDark"
              :src="require('@/assets/images/logo/logo-dark.png')"
              alt="logo"
            />
            <b-img
              v-else
              :src="appLogoImage"
              alt="logo"
            />
          </span>
          <h2
            class="mb-0"
            :class="isDark ? 'brand-text' : 'brand-text-dark'"
          >
            {{ appName }}
          </h2>

        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
    BImg,

  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      isDark,
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-header{
  transform: translate(-50px);
}
</style>>
